import React from 'react';
import CustomLayout from '../layouts/custom-index';

export default class pageDev404 extends React.Component {

  render() {

    return(
      <CustomLayout>
      </CustomLayout>
    );
  }

}